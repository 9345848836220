<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="blue-text font-weight-bold">
        <router-link :to="{ name: 'adminRoute-coupon-list' }">
          <font-awesome-icon
            class="mr-3"
            :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
            />
          </router-link>
        <span>クーポン情報を編集</span>
        </h4>
      </div>
    </div>
    <form v-on:submit="updateCouponDetail">
      <div class="form-group row">
        <label for="id" class="col-sm-3 col-form-label">クーポンID:</label>
        <div class="col-sm-9">
          <input type="text" v-model="couponData.id" name="id" id="id" class="form-control" disabled>
        </div>
      </div>
      <div class="form-group row">
        <label for="name" class="col-sm-3 col-form-label">クーポン名:</label>
        <div class="col-sm-9">
          <input type="text" name="name" id="name" v-model="couponData.name" class="form-control" v-on:keyup="formMessage = null">
        </div>
      </div>
      <div class="form-group row">
        <label for="first_name" class="col-sm-3 col-form-label">利用制限:</label>
        <div class="col-sm-9">
          <b-form-radio-group
            v-model="couponData.type"
            name="type"
          >
            <b-form-radio value="1">1回のみ</b-form-radio>
            <b-form-radio value="2">複数回可能</b-form-radio>
            <b-form-radio value="3">期間内何度でも可能</b-form-radio>
          </b-form-radio-group>
        </div>
      </div>
      <div class="form-group row">
        <label for="code" class="col-sm-3 col-form-label">クーポンコード:</label>
        <div class="col-sm-9">
          <input type="text" name="code" id="code" v-model="couponData.code" class="form-control" v-on:keyup="formMessage = null">
        </div>
      </div>
      <div class="form-group row">
        <label for="available_count" class="col-sm-3 col-form-label">利用可能回数:</label>
        <div class="col-sm-9">
          <input type="text" name="available_count" id="available_count" v-model="couponData.available_count" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="amount" class="col-sm-3 col-form-label">割引金額:</label>
        <div class="col-sm-9">
          <input type="text" name="amount" id="amount" v-model="couponData.amount" class="form-control" v-on:keyup="formMessage = null">
        </div>
      </div>
      <div class="form-group row">
        <label for="birth_date" class="col-sm-3 col-form-label">有効期限(開始日):</label>
        <div class="col-sm-9">
          <v-date-picker
            v-model="couponData.start_at"
            :masks="{ L: 'YYYY-MM-DD' }"
            locale="ja"
            mode="datetime"
          >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="date-picker-wrap">
              <input
                :value="inputValue"
                v-on:click="togglePopover"
                v-on:keyup="formMessage = null"
                class="form-control datepicker-input shadow-1"
                placeholder="YYYY-MM-DD HH:mm:ss"
                required
              />
            </div>
          </template>
        </v-date-picker>
        </div>
      </div>
      <div class="form-group row">
        <label for="birth_date" class="col-sm-3 col-form-label">有効期限(終了日):</label>
        <div class="col-sm-9">
          <v-date-picker
            v-model="couponData.end_at"
            :masks="{ L: 'YYYY-MM-DD' }"
            locale="ja"
            mode="datetime"
          >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="date-picker-wrap">
              <input
                :value="inputValue"
                v-on:click="togglePopover"
                v-on:keyup="formMessage = null"
                class="form-control datepicker-input shadow-1"
                placeholder="YYYY-MM-DD HH:mm:ss"
              />
            </div>
          </template>
        </v-date-picker>
        </div>
      </div>
      <div class="col-12">
        <b-alert
          class="m-0 mt-3 light-grey-outline grey-text"
          variant="warning"
          show
          v-if="formMessage !== null"
        >
          {{ formMessage }}
        </b-alert>
      </div>
      <div class="col-12 col-md-12 col-lg-12 mt-5">
        <router-link
          :to="{
            name: 'adminRoute-coupon-list',
          }"
        >
          <button type="button" class="btn btn-ds grey white-text">キャンセル</button>
        </router-link>
      </div>
      <div class="col-12 col-md-12 col-lg-12 mt-5">
        <button
          class="btn btn-ds dark-blue white-text"
          type="submit"
          v-waves.light
        >
          更新
        </button>
      </div>
    </form>
    <b-modal
    id="coupon-detail-update-success-modal"
    class="border-radius-2"
    size="md"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    centered
  >
    <h5
      class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
      v-on:click="$bvModal.hide('coupon-detail-update-success-modal')"
    >
      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
    </h5>
    <div class="container text-center">
      <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
      <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
        クーポン情報が更新されました
      </h5>
      <div class="row mb-3 mt-5">
        <div class="col-12">
          <router-link :to="{ name: 'adminRoute-coupon-list' }">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('coupon-detail-update-success-modal')"
            >
              OK
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </b-modal>
  </div>
</template>
<script>
    // Import Vuex/Store
    import { adm_stagingService_GetCouponDetailsByID } from '../../services/admin/staging';
    import { adm_stagingService_UpdateCouponDetail } from '../../services/admin/staging';
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';

    export default {
      components: {
        'v-date-picker': DatePicker,
      },
      data() {
        return {
          couponData: null,
          formMessage: null,
        };
      },
      mounted() {
        this.getCouponData();
      },
      computed: {
        ...mapGetters(['currentUser']),
      },
      methods: {
        getCouponData() {
          let _this = this;

          let accessToken = localStorage.getItem('_accessToken');
          let couponId = location.pathname.split('/admin/coupon/edit/')[1];

          adm_stagingService_GetCouponDetailsByID({ id: couponId, token: accessToken })
          .then((response) => {
              let data = response.data.result;
              console.log(data);
      
              _this.couponData = data;

          })
          .catch((error) => {
              console.log(error);
          });
        },

        formatDate(date) {
          if (date == null) {
            return '';
          }
          return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },

        updateCouponDetail(e) {
          let _this = this;

          let accessToken = localStorage.getItem('_accessToken');
          let couponId = location.pathname.split('/admin/coupon/edit/')[1];

          e.preventDefault();

          _this.formMessage = null;

          _this.$store.state.modalLoaderMessage = '保存中';
          _this.$bvModal.show('modal-loader');

          if (_this.couponData.name == '') {
            _this.formMessage = 'Name is required.';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
            return;
          }
          if (_this.couponData.type == '') {
            _this.formMessage = 'Type is required.';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
            return;
          }
          if (_this.couponData.code == '') {
            _this.formMessage = 'Code is required.';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
            return;
          }
          if (_this.couponData.amount == '') {
            _this.formMessage = 'Amount is required.';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
            return;
          }

          adm_stagingService_UpdateCouponDetail({
            id: couponId,
            name: _this.couponData.name,
            type: _this.couponData.type,
            code: _this.couponData.code,
            available_count: _this.couponData.available_count,
            amount: _this.couponData.amount,
            start_at: _this.formatDate(_this.couponData.start_at),
            end_at: _this.formatDate(_this.couponData.end_at),
            token: accessToken,
          })
            .then((response) => {
              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
                _this.$bvModal.show('coupon-detail-update-success-modal');
              }, 500);
              console.log(response);
              if (!response.data.is_succeeded) {
                _this.formMessage =
                  'クーポン情報の更新中にエラーが起こりました。再度お試しください。';
                  let messages = response.data.message;
                  for (let key in messages) {
                    _this.formMessage += key + ' is ' + messages[key] + '.';
                  }             
              }
            })
            .catch((error) => {
              console.log(error);

              _this.formMessage =
                'クーポン情報の更新中にエラーが起こりました。再度お試しください。';

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            });
        },
      }
    }
</script>